import TipDialog from "~~/components/pages/landingCombo/Tip.vue"
import { createVNode, render } from "vue"
import { ErrorTipType, errorTipList } from "@/constant/pages/landingCombo/index"

export type UseLandingComboTipDialogOptions = {
  type?: ErrorTipType
  btnText?: string
  couponCode?: string
  onConfirm?: (cb: () => void) => void
}

export const useLandingComboTipDialog = (opts: UseLandingComboTipDialogOptions = {}) => {
  const { type, btnText = useTranslateI18n(`payment.topCard[1].btn[0]`), couponCode, onConfirm } = opts
  // 挂载  因为dialog是teleport 无需append to body
  const { title, desc } = errorTipList(couponCode)[type]

  const vm = createVNode(TipDialog, {
    ref: "landingComboErrorTipRef",
    title,
    desc,
    type,
    btnText,
    couponCode,
    onConfirm
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
