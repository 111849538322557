<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="error-combo-tip_dialog"
    :dialog-style="dialogStyle"
    :z-index="2002"
    @close="goPage"
  >
    <div v-if="type !== ErrorTipType.Failed3" class="error-tip-content">
      <div class="title">{{ title }}</div>
      <div class="content" v-html="desc"></div>
      <CommonButton ref="btnRef" :button-style="buttonStyle" @btn-click="goPage">
        <CommonAnimationScrollText>
          {{ useTranslateI18n(`landing.landingCombo.btnText`) }}
        </CommonAnimationScrollText>
      </CommonButton>
      <div class="jump-s" v-html="useTranslateI18n(`landing.landingCombo.jump`, [c])"></div>
    </div>

    <div v-else class="error-tip-content-coupon error-tip-content">
      <div class="title">{{ useTranslateI18n(`landing.landingCombo.errorDialog[3].title1`) }}</div>
      <div class="content" v-html="title"></div>
      <div class="coupon">
        <span class="coupon-t" v-html="desc"></span>
        <span class="copy" @click="copyLink">
          <div class="copy-inner">
            <CommonImage :src="getCloudAssets('/images/common/copy.png')" />
          </div>
        </span>
      </div>
      <CommonButton ref="btnRef" :button-style="buttonStyle" @btn-click="goPage">
        <CommonAnimationScrollText>
          {{ btnText }}
        </CommonAnimationScrollText>
      </CommonButton>
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue"
import { PAYMENT_PAGE_PATH } from "@/constant/route"
import { useTranslateI18n } from "~~/composables/store/i18n-country"
import { ErrorTipType, coupon } from "@/constant/pages/landingCombo/index"
import { copy } from "~~/utils/utils"

interface IProps {
  title: string
  desc: string
  type: ErrorTipType
  btnText: string
  couponCode: string
  onConfirm?: (cb: () => void) => void
}

const props = withDefaults(defineProps<IProps>(), {
  title: "",
  desc: "",
  type: null,
  couponCode: coupon,
  btnText: "",
  onConfirm: null
})

let dialogStyle = reactive<CSSProperties>({
  width: 438,
  backgroundColor: "#fff",
  padding: "36px 44.5px"
})

const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  borderRadius: "60px",
  height: "42px",
  color: "#000"
})

const showDialog = ref(true)

const closeDialog = () => {
  showDialog.value = false
}

const goPage = () => {
  if (props.onConfirm) {
    props.onConfirm(closeDialog)
    return
  }
  closeDialog()
  location.href = unref(PAYMENT_PAGE_PATH)
}

const copyLink = useDebounceFn(async () => {
  await copy(props.couponCode)
  useSuccessMessage(useTranslateI18n("landing.landingCombo.copy1"))
}, 300)

let { c } = useNoStorageCountDown(3, 1, () => {
  if (props.type !== ErrorTipType.Failed3) {
    goPage()
  }
})

// 3s 跳回
</script>

<style lang="scss">
.welcome-page-dialog {
  &.error-combo-tip_dialog {
    @include device-max-width-600 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .content-d {
          padding: 20px !important;
        }
        .close-icon {
          right: -32px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.error-tip-content {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 24px;
    line-height: 29px;
    @include fontSemibold;
  }
  .content {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.55);
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .jump-s {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    margin-top: 12px;
    color: rgba(0, 0, 0, 0.55);
  }
}

.error-tip-content-coupon {
  .title {
    font-size: 24px;
    @include fontSemibold;
  }
  .content {
    margin: 24px 0;
  }
  .coupon {
    @include fontMedium;
    margin-top: 0;
    font-size: 16px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .copy {
      flex-shrink: 0;
      margin-left: 8px;
      cursor: pointer;
      padding: 1px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
      justify-content: center;
      .copy-inner {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
